<template>
  <div>
    <PatientMainPage
      v-if="this.$store.getters['user/getUser'].role === 'patient'"
    ></PatientMainPage>
    <PsyhMainPage
      v-if="this.$store.getters['user/getUser'].role === 'psychologist'"
    ></PsyhMainPage>
  </div>
</template>

<script>
const PatientMainPage = () =>
  import("../../components/dashboard/patientDashboard/patientMainPage");

const PsyhMainPage = () =>
  import("../../components/dashboard/psyhDashboard/PsyhMainPage");

export default {
  name: "dashboard",
  components: { PatientMainPage, PsyhMainPage },
};
</script>

<style scoped></style>
